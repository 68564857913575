import axios from "axios";  

import * as data from '../../../config.json';

const config = data;

export const http = axios.create({  
  baseURL: config.http.base_url,  
  headers: {  
    'Authorization-Key' : config.http.authorization.key,
    'Content-type' : 'application/json'  
  }
});

export const streamClient = axios.create({  
  baseURL: config.http.base_url,  
  headers: {
    'Authorization-Key' : config.http.authorization.key,
    'Content-type' : 'application/json',  
  },
  responseType: 'stream'
});

export function isSuccess(status: number) {
  return (status == 200 || status == 202)
}

export function isUnauthenticated(status: number) {
  return status == 403
}