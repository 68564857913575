export function formatNumeric(str: string | number) {
    const query  = str.toString().split('').reverse()
    const length = query.length

    const array    = query.map((el, i) => {
        const val  = (i + 1)
        const cond = val % 3 === 0 && val < length
        let resp   = el

        if (cond) resp = resp + " "

        return resp
    })
    
    const result = array.join('')
    .split('')
    .reduce((prev,next) => next + prev)
    
    return result
}

export const industryTypes = ["E Commerce", "Game Development", "Online Publishing", "Others"]

export function returnDataURI(file: File, callback: (str: string) => void) {
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result as string)
    };
    reader.readAsDataURL(file);
}

import * as config_data from '@/../config.json';
import { useRouter } from 'vue-router'

const config = config_data

export function bake_cookie(user: string, token: string) {
    localStorage.setItem(config.access.user, user)
    localStorage.setItem(config.access.key, token)
}

export function get_cookie(key: string): string | null {

    if (key == config.access.key) {
        return localStorage.getItem(config.access.key)
    } else if (key == config.access.user) {
        return localStorage.getItem(config.access.user)
    } else return null;
}

export function delete_cookie() {
    localStorage.clear()
}

export function invalidReroute() {
    localStorage.clear()
    const router = useRouter()
    router.replace('/auth/sign-in')
}

export function getSource(type: number) {
    switch(type) {
        case 1:
            return "Stripe";
        case 2:
            return "Bank Transfer";
        case 3:
            return "Admin";
        case 4:
            return "Free Credits";
        default:
            return "Unknown Payment Type";
    }
}