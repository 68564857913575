
import { defineComponent } from 'vue'
import { IonAvatar, IonCol, IonContent, IonIcon, IonItemDivider, IonLabel, IonRow, IonPage } from '@ionic/vue';
import { arrowForwardOutline, chevronDownOutline, powerOutline } from 'ionicons/icons';
import { get_cookie } from '@/utils/helper';
import * as config_data from '@/../config.json';
import { useRouter } from 'vue-router';
import { UserDocument } from '@/utils/interfaces';

const config = config_data


window.addEventListener('click', (event) => {
    const target = event.target as HTMLElement;

    if (!target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
        }
        }
    }
});


export default defineComponent({
    components: { IonAvatar, IonCol, IonContent, IonIcon, IonItemDivider, IonLabel, IonRow, IonPage },
    created() {
        const _user: string | null  = get_cookie(config.access.user)
        const _token: string | null = get_cookie(config.access.key)

        let isInvalid = (str: string | null) => {
            return (str == null || str == '')
        }

        if (isInvalid(_user) || isInvalid(_token)) {
            const router = useRouter()
            router.replace('/auth/sign-in')
        } else {
            this.user = JSON.parse(_user ?? '');   
            this.$emit("user", JSON.parse(_user ?? ''))
            this.$emit("token", _token ?? '')
            this.$emit("complete")
        }

    },
    methods: {
        showDropdown() {
            document.getElementById("nav-dropdown")?.classList.toggle("show");
        },
        dashboardPage() {
            this.$router.push('/dashboard')
        },
        documentationPage() {
            window.open(config.welcome.url + "/documentation", "_blank") 
        },
        billingPage() {
            this.$router.replace('/billing')
        },
        getHelp() {
            this.$router.push('/dashboard')
        },
        signOutPage() {
            localStorage.clear()
            this.$router.replace('/auth/sign-in')
        },
        profilePage() {
            this.$router.push('/profile')
        },
        privacyPage() {
            window.open(config.welcome.url + "/privacy", "_blank")
        },
        tosPage() {
            window.open(config.welcome.url + "/terms", "_blank")
        }
    },
    data() {
        return {
            user: {} as UserDocument,
            isNavModalOpen: false,
            arrowForwardOutline,
            chevronDownOutline,
            powerOutline,
            welcomePage: config.welcome.url
        }
    },
    props: {
        pageTitle: { type: String }
    }
})
