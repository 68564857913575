
import { defineComponent } from 'vue'
import { IonIcon, IonCol, IonRow, IonButton, IonButtons, IonContent, IonCard, IonItem, IonInput, IonLabel, IonHeader, IonPage, IonTitle, IonToolbar, IonSpinner } from '@ionic/vue';
import { addOutline, closeOutline, keyOutline } from 'ionicons/icons';
import { NewServicePayload } from '@/utils/interfaces';
import { presentErrorToast, presentToast } from '@/utils/toast';
import { returnDataURI } from '@/utils/helper';

export default defineComponent({
    components: { IonIcon, IonCol, IonRow, IonButton, IonButtons, IonContent, IonCard, IonItem, IonInput, IonLabel, IonHeader, IonPage, IonTitle, IonToolbar, IonSpinner },
    methods: {
        cancel() { this.$emit('cancel') },
        addImage() {
            const array: any = document.querySelectorAll('input[type=file]');
            if (this.svc.avatar == "") {
                presentErrorToast("Avatar cannot be null")
            } else {
                const file: File = array[0].files[0]
                // console.log(national_id_file)
                var filesize = file.size;
                if(filesize > 150000){
                    this.svc.avatar = ""
                    presentErrorToast("File should be less than 150 kb")
                } else {
                    var fileName  = file.name;
                    var extension = fileName.split('.').pop();
                    if(extension!=='jpeg'&& extension!=='jpg'&& extension!=='png'){
                        this.svc.avatar = ""
                        presentErrorToast("Error file type.");
                    }else{
                        returnDataURI(file, (result: string) => {
                            this.svc.avatar = result
                            presentToast("Image Added Successfully")
                        }
                    )
                    }
                } 
                
            }
        },
        confirm() {
            if (this.svc.avatar == '') presentErrorToast("Add Service Logo to Proceed.")
            else this.$emit('confirm', this.svc)
        }
    },
    data() {
        var svc: NewServicePayload = {
            access_token: '',
            name: '',
            avatar: ''
        }

        return {
            svc,
            addOutline, 
            closeOutline,
            keyOutline
        }        
    },
    props: {
        isLoading: {
            default: false,
            type: Boolean
        }
    }
})
