
import { add, ribbonOutline, radioButtonOffSharp, ellipsisVerticalSharp } from 'ionicons/icons';
import { IonAvatar, IonButton, IonCard, IonSpinner, IonCol, IonIcon, IonLabel, IonModal, IonRow } from '@ionic/vue'
import { defineComponent } from 'vue'
import { http, isSuccess, isUnauthenticated } from '../utils/http/index';
import { presentErrorToast, presentToast } from '../utils/toast';
import { invalidReroute } from '../utils/helper/index'
import { NewServicePayload, Service, UserDocument, Wallet } from '@/utils/interfaces';
import AddServiceComponent from '@/views/components/sub/AddServiceComponent.vue';
import DailyCallGraph from '@/views/components/sub/DailyCallGraph.vue';


export default defineComponent({
    components: { AddServiceComponent, DailyCallGraph, IonAvatar, IonButton, IonCard, IonSpinner, IonCol, IonIcon, IonLabel, IonModal, IonRow },
    data() {

        return {
            add,
            accessToken: '' as string | null,
            recentActivities: [],
            wallet: {} as Wallet,
            isAddServiceOpen: false,
            isAddSvcLoading: false,
            isLoading: false,
            ellipsisVerticalSharp,
            radioButtonOffSharp,
            ribbonOutline,
            totalDailyCallsNo: 0,
            svcs: [] as Array<Service>,
            user: {} as UserDocument,
            title: 'Daily Call Rate History',
            dataList: [] as Array<number>
        }
    },
    methods: {
        setToken(token: string) { this.accessToken = token },
        setUser(user: UserDocument) { 
            this.user = user
        },
        complete() { 
            this.fetch()
        },
        encrypt(str: string) { return btoa(str) },
        cancel() {
            this.isAddServiceOpen = false;
        },
        billingPage() {
            this.$router.replace('/billing')
        },
        confirm(data: NewServicePayload) {
            data.access_token    = this.accessToken ?? ''
            this.isAddSvcLoading = true;

            http.post(`/creator/service`, data)
            .then(res  => {
                this.isAddSvcLoading = false;
                if (!isSuccess(res.status)) {
                    presentErrorToast(res.data.message)
                } else {
                    presentToast("Successfully Created Service")
                    this.isAddServiceOpen = false;
                    this.fetch()
                }
            }).catch(e => {
                this.isAddSvcLoading = false;
                if (e.response) { 
                    if (isUnauthenticated((e.response.status))) {
                        invalidReroute()
                    } else presentErrorToast(e.response.data.message) 
                } else this.$router.push('/error')
            })
        },
        fetch(){
            http.get(`/creator/insight/daily-call-rate?access_token=${this.accessToken}`)
            .then(res  => {
                this.isLoading = false;
                if (!isSuccess(res.status)) {
                    if (res.status == 510) {
                        presentErrorToast(res.data.message)
                    } else presentErrorToast(res.data.message)
                } else {
                    const data             = res.data.response
                    this.totalDailyCallsNo = data.totalDailyCallsNo
                    this.svcs              = data.services
                    this.dataList          = data.dailyRequestHistory.reverse()
                    this.recentActivities  = data.recentActivities
                }
            }).catch(e => {
                this.isLoading = false;
                if (e.response) { 
                    if (isUnauthenticated((e.response.status))) {
                        invalidReroute()
                    } else presentErrorToast(e.response.data.message) 
                } else {
                    this.$router.push('/error')
                }
            })
            this.getBalance()
        },
        getBalance() {
            http.get(`/creator/billing/balance?access_token=${this.accessToken}`)
            .then(res  => {
                if (!isSuccess(res.status)) {
                    presentErrorToast(res.data.message)
                } else {
                    this.wallet = res.data.response
                }
            }).catch(e => {
                if (e.response) { 
                    if (isUnauthenticated((e.response.status))) {
                        invalidReroute()
                    } else presentErrorToast(e.response.data.message) 
                } else this.$router.push('/error')
            })
        },
        openServiceDetails(serviceId: string) {            
            this.$router.push('/services/'+btoa(serviceId))
        }
    }
})
