import { toastController } from '@ionic/vue';
import { checkbox, trashBinOutline, warningOutline } from 'ionicons/icons';

const duration = 2500

export async function presentToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: duration,
        position: 'top',
        icon: checkbox,
        buttons: [
            {
                text: 'Dismiss',
                role: 'cancel'
            }
        ]
    });
    
    await toast.present();
}

export async function presentErrorToast(message: string) {
    const toast = await toastController.create({
        message: message,
        duration: duration,
        position: 'top',
        icon: warningOutline,
        buttons: [
            {
                text: 'Dismiss',
                role: 'cancel'
            }
        ]
    });
    
    await toast.present();
}

export async function presentDeleteDialog(message: string, fn: () => void) {
    const toast = await toastController.create({
        message: message,
        position: 'top',
        icon: trashBinOutline,
        buttons: [
            {
                text: 'Dismiss',
                role: 'cancel'
            },
            {
                text: 'Proceed',
                handler: fn
            }
        ]
    });
    
    await toast.present();
}