<template>
    <a href="/" style="text-decoration: none; color: var(--ion-color-dark);">
        <h1 class="title">
            OPEN<strong>CIDER </strong>
            <span><img style="width: 21px; height: 21px;" src="assets/icon/icon.png" /></span>
        </h1>
    </a>
</template>

<style>
.title {
    padding-left: 1em;
}
    
@media screen and (max-width: 600px) {
    .title {
        padding-left: 27%;
    }
}
</style>